import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { AccountItem } from "../../../core/models/accountItem";
import { ConfigService } from "../../../core/services/config.service";
import { VisibilityService } from "../../../core/services/visibility.service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit, OnDestroy {
    items: AccountItem[] = [
        { icon: 'assets/icons/settings.svg', name: 'Settings' },
        { icon: 'assets/icons/logout.svg', name: 'Logout' }
    ];
    accountImage: string = 'assets/icons/user.svg';

    isInteractionAllowed!: boolean;
    isVisibleMenu!: boolean;
    agentName!: string;

    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    visibilitySubscription!: Subscription;

    // Close dropdown when clicking outside
    @HostListener('document:click', ['$event'])
    clickoutAccountMenu(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isVisibleMenu = false;
        }
    }

    constructor(
        private elementRef: ElementRef,
        private language: LanguageService,
        private interaction: InteractionService,
        private config: ConfigService,
        private visibility: VisibilityService) {
    }

    ngOnInit(): void {
        this.agentName = this.config.getAgentName();

        this.visibilitySubscription = this.visibility.getVisibility('avatar-account-menu').subscribe(visible => {
            this.isVisibleMenu = visible;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);

            this.items = [
                { icon: 'assets/icons/settings.svg', name: translate.typography.settings },
                { icon: 'assets/icons/logout.svg', name: translate.typography.logout }
            ];
        });
    }

    toggleDropdown() {
        this.visibility.toggleComponent('avatar-account-menu');
    }

    toggleOption(selectedOption: any) {
        console.log(selectedOption.name);
    }

    ngOnDestroy(): void {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
    }
}
