<div class="box-languages">
    <button class="language-container" id="id-language" [disabled]="!isInteractionAllowed"
            (click)="toggleDropdown()" tabindex="0"
    >
        <p class="selected-language-name">{{ selectedLanguage.shortName }}</p>
        <div *ngIf="isVisibleMenu" class="language-menu">
            <div *ngFor="let language of languages">
                <div class="menu-item" tabindex="0"
                    [ngClass]="getClass(language)" (click)="toggleLanguage(language)"
                >
                    <img class="menu-item-img" [src]="language.icon" alt="Language icon">
                    <p class="menu-item-name" >{{ language.name }}</p>
                </div>
            </div>
        </div>
    </button>
</div>
