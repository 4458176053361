import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { WebSocketService } from "../../../core/services/web-socket.service";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-lost-network',
    templateUrl: './lost-network.component.html',
    styleUrl: './lost-network.component.scss'
})
export class LostNetworkComponent implements OnInit, OnDestroy {
    hubLogo: string = "assets/icons/hub71.svg"
    lostConnectionImage: string = "assets/images/lostConnection.png"
    lostConnectionArrows: string = "assets/images/lostConnectionArrows.png"

    lostConnectionName: string = "Lost Connection"
    lostConnectionDescription: string = "You have lost connection with system"
    reloadButton: string = "Reload"
    reportButton: string = "Report issue"

    blurSubscription!: Subscription;
    isBlured: boolean = false;

    constructor (
        protected socket: WebSocketService,
        private event: EventService) { }

    ngOnInit(): void {
        this.blurSubscription = this.event.blurEfectEvent.subscribe(isBlured => {
            this.isBlured = isBlured
        });
    }

    async reload() {
        await this.socket.initConnection();
    }

    reportIssue() {
        this.event.blurEfectEvent.emit(true);
    }

    ngOnDestroy(): void {
        if (this.blurSubscription) this.blurSubscription.unsubscribe();
    }
}
