import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-enter',
    template: `
        <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_392_1042)">
                <path d="M13.5385 1.46155L9.23077 13.7692L6.76924 8.23078L1.23077 5.76924L13.5385 1.46155Z" stroke="#BBA9E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.5385 1.46155L6.76923 8.23078" stroke="#BBA9E6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_392_1042">
                    <rect width="14.7692" height="14.7692" fill="white" transform="translate(0 0.230774)"/>
                </clipPath>
            </defs>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-enter-color: #F0ECFA;
        }
        svg path {
            stroke: var(--stroke-enter-color);
        }
    `]
})
export class EnterComponent {
    @Input() stroke: string = 'var(--stroke-enter-color)';
}
