import { Injectable } from '@angular/core';
import { ConfigService } from "./config.service";
import { BehaviorSubject } from "rxjs";
import { Language } from "../models/language";
import { TRANSLATION_CONFIG } from "../../app.config";
import {LanguageModel} from "../models/project";

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    defaultLanguage: LanguageModel | null = null;
    designTranslation: any[] = TRANSLATION_CONFIG
    selectedLanguageSubject = new BehaviorSubject<any>(null);
    selectedLanguage$ = this.selectedLanguageSubject.asObservable();

    constructor(private config: ConfigService) {
        const lang = this.getDefaultLanguage()
        if (lang !== null) { this.setLanguage(lang.locale); }
    }

    // getters
    getSelectedLanguage() {
        return this.selectedLanguageSubject.value || this.getDefaultLanguage();
    }

    getLanguage(selectedLanguage: any) {
        return (
            this.config.getLanguages().find(l => l.locale === selectedLanguage) ?? this.getSelectedLanguage()
        );
    }

    getDefaultLanguage() {
        if (!this.defaultLanguage) {
            const language = this.config.getLanguages().find(language => Math.min(language.order ?? 0));
            if (language) {
                this.defaultLanguage = language
            }
        }

        if (!this.defaultLanguage) {
            console.error('Default language is not defined.');
            throw new Error("default language is not defined")
        }

        return this.defaultLanguage;
    }

    getDesignTranslation(locale: string) {
        return this.designTranslation.find(translate => translate.locale === locale) ?? this.getDefaultTranslation()
    }

    getDefaultTranslation() {
        return this.designTranslation.find(translate => translate.locale === this.getDefaultLanguage()?.locale)
    }

    // setters
    setLanguage(locale: string): void {
        if (!locale) {
            console.error('Language locale is not defined.');
            return;
        }

        const selectedLanguage = this.getLanguage(locale);
        this.selectedLanguageSubject.next(selectedLanguage);
    }
}
