<div class="box-lost-connection" [ngClass]="{'blurred': isBlured}" *ngIf="!socket.getConnectionStatus()">
    <div class="lost-connection-container">
        <div class="lost-connection-inside-container">
            <div class="lost-connection-title">
                <div class="box-logo">
                    <div class="logo-container">
                        <div class="company-logo-container">
                            <img [src]="hubLogo" alt="Company Logo" />
                        </div>
                    </div>
                </div>
                <div class="lost-connection-text-container">
                    <div class="lost-connection-name">
                        <h1> {{ lostConnectionName }}</h1>
                    </div>
                    <div class="lost-connection-description-container">
                        <p> {{ lostConnectionDescription }} </p>
                    </div>
                </div>
                <div class="buttons-container">
                    <button id="id-reload" type="button" class="reload-button" (click)="reload()"> {{ reloadButton }} </button>
                    <button id="id-report-issue" type="button" class="report-button" data-bs-toggle="modal"
                            data-bs-target="#reportIssueModal" data-target=".bd-example-modal-lg" (click)="reportIssue()">
                        {{ reportButton }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="lost-connection-images">
        <div class="lost-connection-image-container">
            <img [src]="lostConnectionImage" alt="Lost connection image" />
        </div>
        <div class="lost-connection-arrows-image">
            <img [src]="lostConnectionArrows" alt="Lost connection arrows image" />
        </div>
    </div>
</div>
<app-report-issue idModal="reportIssueModal" labelModal="reportIssuelabelModal"></app-report-issue>

