<div *ngIf="isVisible" class="box-introduction">
    <div class="get-in-touch-container">
        <h1>Get in touch</h1>
        <p>Our AI assistant is here to help connect startups with investors and <br> guide you through every step of your journey.</p>
        <div class="character-container">
            <app-character></app-character>
        </div>
    </div>
    <div class="horizontal-scroll-container">
        <app-shortcuts></app-shortcuts>
    </div>
</div>
