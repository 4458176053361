import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { AnimationService } from "../../../core/services/animation.service";

@Component({
  selector: 'app-character',
  templateUrl: './character.component.html',
  styleUrl: './character.component.scss'
})
export class CharacterComponent implements AfterViewInit {
    @ViewChild('animationContainer') animationContainer!: ElementRef;

    constructor(private animation: AnimationService) { }

    async ngAfterViewInit() {
        await this.animation.init(this.animationContainer);
        this.animation.startDefaultAnimation();
    }
}
