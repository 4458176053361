<div *ngIf="isValidHyperlink" class="hyperlink-container" (click)="openHyperlink()" tabindex="0" appFadeInOut>
    <img *ngIf="isValidImage(hyperlinkInfo?.image)" class="hyperlink-image" [src]="hyperlinkInfo?.image" alt="Hyperlink image">
    <div class="hyperlink-text-content">
        <p class="hyperlink-title">{{ hyperlinkInfo?.title }}</p>
        <p class="hyperlink-description">{{ truncateDescription(hyperlinkInfo?.description, 430) }}</p>
        <div class="link-container">
            <img [src]="linkImage" alt="Link image">
            <p class="hyperlink-link">{{ hyperlinkInfo?.url }}</p>
        </div>
    </div>
</div>
