<div class="modal fade bd-example-modal-lg" data-bs-backdrop='static' tabindex="-1" role="dialog" aria-hidden="true"
     [id]="idModal" [attr.aria-labelledby]="labelModal" (keydown.escape)="closeReportIssue()">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="report-issue-title">{{ reportIssueHeader }}</p>
                <button id="id-close-report-issue" class="report-issue-close" data-bs-dismiss="modal" type="button" aria-label="Close" (click)="closeReportIssue()">
                    <app-close></app-close>
                </button>
            </div>
            <div class="modal-body">
                <textarea id="id-report-issue-reason" [placeholder]="placeholderText" [(ngModel)]="reportIssueText"></textarea>
            </div>
            <div class="modal-footer">
                <button id="id-submit-issue" data-bs-dismiss="modal" class="submit-issue" type="button"
                        (click)="submitIssue()" [disabled]="!formFilled()">{{ buttonText }}</button>
            </div>
        </div>
    </div>
</div>
