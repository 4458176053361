<div class="box-quick-links" appFadeInOut>
    <div class="quick-links-container-wrapper">
        <p class="quick-links-title">{{ quickLinksTitle }}</p>
        <ul class="quick-links-container-list">
            <button *ngFor="let topic of quickLinkTopics; let last = last" id="id-topic"
                    class="topic-container-item"
                    [disabled]="!isInteractionAllowed"
                    (click)="dropTopic(topic)"
            >
                <span class="topic-arrow-container" [ngClass]="{ 'selected-topic': selectedTopic === topic }">
                    <p class="topic-name">{{ topic.topic }}</p>
                    <button *ngIf="selectedTopic !== topic" [disabled]="!isInteractionAllowed" id="id-down-arrow" type="button" class="arrow-down">
                        <app-arrow-down></app-arrow-down>
                    </button>
                    <button *ngIf="selectedTopic === topic" [disabled]="!isInteractionAllowed" id="id-top-arrow" type="button" class="arrow-down">
                        <app-arrow-top></app-arrow-top>
                    </button>
                </span>
                <div class="quick-links-subtopics" [ngClass]="{'open': selectedTopic === topic}">
                    <ul>
                        <li (click)="onTopicClick(quickLink)" *ngFor="let quickLink of topic.quickLinks">{{ quickLink.subtopic }}</li>
                    </ul>
                </div>
                <hr *ngIf="!last">
            </button>
        </ul>
    </div>
</div>
