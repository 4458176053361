import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-arrow-down',
    template: `
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Expand">
                <path id="Expand_Less" d="M13.572 17.8407C13.8052 18.0531 14.1948 18.0531 14.4286 17.8407L19.8229 12.9404C20.059 12.7253 20.059 12.3764 19.8229 12.1619C19.5867 11.9468 19.2031 11.9468 18.9669 12.1619L14 16.6729L9.03371 12.1613C8.79693 11.9462 8.41391 11.9462 8.17713 12.1613C7.94095 12.3764 7.94095 12.7253 8.17713 12.9398L13.572 17.8407Z" [attr.fill]="stroke"/>
                <path id="Vector 187" d="M20 12L14 18L8 12" stroke="#7E7C85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-arrow-color: #C6D0DA;
        }
        svg path {
            stroke: var(--stroke-arrow-color);
        }
    `]
})
export class ArrowDownComponent {
    @Input() stroke: string = 'var(--stroke-arrow-color)';
}
