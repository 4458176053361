import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { MessageService } from "../../../core/services/message.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { RelatedQuestion } from "../../../core/models/relatedQuestion";
import { VisibilityService } from "../../../core/services/visibility.service";

@Component({
    selector: 'app-related-questions',
    templateUrl: './related-questions.component.html',
    styleUrl: './related-questions.component.scss'
})
export class RelatedQuestionsComponent implements OnInit, OnDestroy {
    relatedQuestionsTitle: string = 'Explore More';
    relatedQuestionsList: RelatedQuestion[] = [];

    isInteractionAllowed: boolean = false;
    isLoading: boolean = false;

    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    relatedQuestionsSubscription!: Subscription;
    loaderSubscription!: Subscription;

    constructor(
        private language: LanguageService,
        private message: MessageService,
        private interaction: InteractionService,
        private visibility: VisibilityService) { }

    ngOnInit(): void {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.relatedQuestionsTitle = translate.typography.relatedQuestionsTitle;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.loaderSubscription = this.visibility.getVisibility('avatar-loader').subscribe(async loading => {
            this.isLoading = loading;
        });

        this.relatedQuestionsSubscription = this.message.getMessageRelatedQuestion().subscribe(questions => {
            this.relatedQuestionsList = questions;
        });
    }

    async onRelatedQuestionSubmit(value: string) {
        await this.message.createConversationRequest("text", 'text', value);
    }

    get isRelatedQuestion(): boolean {
        return this.relatedQuestionsList.length > 0;
    }

    ngOnDestroy() {
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.relatedQuestionsSubscription) { this.relatedQuestionsSubscription.unsubscribe(); }
        if (this.loaderSubscription) { this.loaderSubscription.unsubscribe(); }
    }
}

