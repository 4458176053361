import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { MessageService } from "../../../core/services/message.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
    hubImage: string = 'assets/icons/hub71.svg';
    loupeIcon: string = 'assets/icons/loupe.svg';

    talkStarted!: boolean;
    isInteractionAllowed!: boolean;

    talkSubscription!: Subscription;
    interactionSubscription!: Subscription;

    constructor(
        private message: MessageService,
        private visibility: VisibilityService,
        private interaction: InteractionService,
        private event: EventService){
    }

    ngOnInit(): void {
        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            this.talkStarted = isTalkStarted;
        });
    }

    newChat() {
        this.message.clearMessages();
        this.visibility.hideComponent('avatar-conversation');
    }

    ngOnDestroy(): void {
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
    }
}
