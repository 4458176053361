import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { LanguageService } from "../../../core/services/language.service";
import { MessageService } from "../../../core/services/message.service";
import { InteractionService } from "../../../core/services/interaction.service";
import { VisibilityService } from "../../../core/services/visibility.service";
import { QuickLink, QuickLinkTopic } from "../../../core/models/quickLink";
import { ConfigService } from "../../../core/services/config.service";

@Component({
    selector: 'app-quick-links',
    templateUrl: './quick-links.component.html',
    styleUrl: './quick-links.component.scss'
})
export class QuickLinksComponent implements OnInit, OnDestroy {
    quickLinksTitle: string = 'Quick Links';

    isInteractionAllowed: boolean = false;
    isLoading: boolean = false;
    quickLinkTopics: QuickLinkTopic[] = [];
    selectedTopic: QuickLinkTopic | null = null;

    translateSubscription!: Subscription;
    interactionSubscription!: Subscription;
    loaderSubscription!: Subscription;

    constructor(
        private language: LanguageService,
        private message: MessageService,
        private interaction: InteractionService,
        private visibility: VisibilityService,
        private config: ConfigService) { }

    ngOnInit(): void {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale);
            this.quickLinksTitle = translate.typography.quickLinksTitle;
            this.quickLinkTopics = translate.typography.quickLinks;
        });

        this.interactionSubscription = this.interaction.getInteractionMode().subscribe(interactionAllowed => {
            this.isInteractionAllowed = interactionAllowed;
        });

        this.loaderSubscription = this.visibility.getVisibility('avatar-loader').subscribe(async loading => {
            this.isLoading = loading;
        });

        //this.quickLinkTopics = this.config.getQuickLinkTopics();
    }

    onTopicClick(quickLink: QuickLink) {
        this.message.createQuickLinkConversation(quickLink);
    }

    dropTopic(topic: QuickLinkTopic) {
        this.selectedTopic = this.selectedTopic === topic ? null : topic;
    }

    ngOnDestroy() {
        if (this.interactionSubscription) { this.interactionSubscription.unsubscribe(); }
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
        if (this.loaderSubscription) { this.loaderSubscription.unsubscribe(); }
    }
}
