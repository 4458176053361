import { Component, Input } from '@angular/core';

import { EventService } from '../../../core/services/event.service';

@Component({
    selector: 'app-report-issue',
    templateUrl: './report-issue.component.html',
    styleUrl: './report-issue.component.scss'
})
export class ReportIssueComponent {
	@Input() idModal!: string;
    @Input() labelModal!: string;

    reportIssueHeader: string = 'Report issue';
    placeholderText: string = 'I have lost connection with system because....';
    buttonText: string = 'Submit';
    reportIssueText!: string;

    constructor(private event: EventService) { }

    closeReportIssue() {
        this.resetReportIssueFields();
        this.event.blurEfectEvent.emit(false);
    }

    resetReportIssueFields(){
        this.reportIssueText = '';
    }

    submitIssue() {
        this.event.blurEfectEvent.emit(false);

        /*const index = this.messages.getMessageIndex();
        const selectedCheckbox = this.checkboxes.find(checkbox => checkbox.checked);
        const selectedOption = selectedCheckbox?.text;

        if (index != null) {
            this.messages.setMessageFeedback(index, false, this.additionalFeedback, selectedOption);
            this.event.feedbackSubmitted.emit(index);
            this.closeReportIssue();
        } else {
            console.warn("Invalid message index.");
        }*/
    }

    formFilled(): boolean {
        return !!(this.reportIssueText && this.reportIssueText.trim() !== "");
    }
}
