import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";

import { ConfigService } from "../../core/services/config.service";
import { LanguageService } from "../../core/services/language.service";
import { EventService } from "../../core/services/event.service";
import { VisibilityService } from "../../core/services/visibility.service";
import { WebSocketService } from "../../core/services/web-socket.service";

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit, OnDestroy {
    @ViewChild('scrollContainer') private scrollContainer!: ElementRef;

    conversationStarted: boolean = false;
    isBlured: boolean = false;
    direction!: any;

    languageSubscription!: Subscription;
    talkSubscription!: Subscription;
    blurSubscription!: Subscription;
    visibilitySubscription!: Subscription;

    constructor(
        private config: ConfigService,
        private language: LanguageService,
        private event: EventService,
        private title: Title,
        private changeDetector: ChangeDetectorRef,
        private socket: WebSocketService,
        private visibility: VisibilityService) {
        this.title.setTitle(this.config.getProjectName());
    }

    async ngOnInit() {
        this.event.scrollToBottomEvent.subscribe(() => {
            this.scrollToBottom();
        });

        this.talkSubscription = this.event.getStartedTalk().subscribe(isTalkStarted => {
            //this.talkStarted = isTalkStarted;
        });

        this.blurSubscription = this.event.blurEfectEvent.subscribe(isBlured => {
            this.isBlured = isBlured
        });

        this.languageSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            this.direction = selectedLanguage.direction;
        });

        this.visibilitySubscription = this.visibility.getVisibility('avatar-conversation').subscribe(async loading => {
            this.conversationStarted = loading;
        });

        this.visibility.showComponent('avatar-introduction');
    }

    scrollToBottom () {
        this.changeDetector.detectChanges();
        if (this.scrollContainer) {
            this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
        }
    }

    closews() {
        this.socket.onClose();
    }

    ngOnDestroy() {
        if (this.languageSubscription) { this.languageSubscription.unsubscribe(); }
        if (this.talkSubscription) { this.talkSubscription.unsubscribe(); }
        if (this.blurSubscription) { this.blurSubscription.unsubscribe(); }
        if (this.visibilitySubscription) { this.visibilitySubscription.unsubscribe(); }
    }
}
