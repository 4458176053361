import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-bar',
    template: `
        <svg [attr.width]="width" [attr.height]="height" [attr.viewBox]="computedViewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect [attr.width]="width" [attr.height]="height" rx="2" fill="#124EF5"/>
        </svg>
    `,
})
export class BarComponent implements OnInit {
    @Input() height: string = '40';
    @Input() width: string = '4';

    constructor(private elRef: ElementRef) {}

    ngOnInit(): void {
        this.adjustHeight();
    }

    adjustHeight(): void {
        const parentHeight = this.elRef.nativeElement.parentElement.offsetHeight;
        this.height = `${parentHeight}px`;
    }

    get computedViewBox(): string {
        const w = parseFloat(this.width);
        const h = parseFloat(this.height) + 5;
        return `0 0 ${w} ${h}`;
    }
}
