import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor() { }

    showError(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            imageUrl: '../../../assets/icons/error.svg',
            imageAlt: 'Custom error image',
            title: title,
            text: text
        });
    }

    showSucess(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            confirmButtonText: 'OK',
            imageUrl: '../../../assets/icons/sucess.svg',
            imageAlt: 'Custom sucess image',
            title: title,
            text: text
        });
    }

    close() {
        Swal.close();
    }
}
