<div *ngIf="isLoadingShown" class="loader-container">
    <div class="inside-loader">
        <img [src]="hubLogo" class="avatar-icon" alt="AI"/>
        <div class="loader">
            <img [src]="hubLoader" class="svg-item svg-item-1" alt="Loading...">
            <img [src]="hubLoader" class="svg-item svg-item-2" alt="Loading...">
            <img [src]="hubLoader" class="svg-item svg-item-3" alt="Loading...">
        </div>
    </div>
</div>
