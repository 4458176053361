import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-arrow-top',
    template: `
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Expand">
                <path id="Expand_Less" d="M14.428 10.1593C14.1948 9.94691 13.8052 9.94691 13.5714 10.1593L8.17713 15.0596C7.94096 15.2747 7.94096 15.6236 8.17713 15.8381C8.41331 16.0532 8.79693 16.0532 9.03311 15.8381L14 11.3271L18.9663 15.8387C19.2031 16.0538 19.5861 16.0538 19.8229 15.8387C20.059 15.6236 20.059 15.2747 19.8229 15.0602L14.428 10.1593Z" [attr.fill]="stroke"/>
                <path id="Vector 187" d="M8 16L14 10L20 16" stroke="#7E7C85" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-arrow-color: #C6D0DA;
        }
        svg path {
            stroke: var(--stroke-arrow-color);
        }
    `]
})
export class ArrowTopComponent {
    @Input() stroke: string = 'var(--stroke-arrow-color)';
}
