import { Directive, ElementRef, Renderer2, Input, OnInit, OnDestroy } from '@angular/core';
import { AnimationBuilder, AnimationFactory, AnimationPlayer, style, animate } from '@angular/animations';

@Directive({
    selector: '[appFadeInOut]'
})
export class FadeInOutDirective implements OnInit, OnDestroy {
    @Input() duration: number = 300; // Optional input for animation duration
    private player: AnimationPlayer | undefined;

    constructor(private el: ElementRef, private renderer: Renderer2, private builder: AnimationBuilder) {}

    ngOnInit(): void {
        // When the element is inserted (enter transition)
        this.playAnimation(true);
    }

    ngOnDestroy(): void {
        // When the element is removed (leave transition)
        this.playAnimation(false);
    }

    private playAnimation(enter: boolean): void {
        const animation: AnimationFactory = this.builder.build([
            style({ opacity: enter ? 0 : 1 }),
            animate(`${this.duration}ms ease-in-out`, style({ opacity: enter ? 1 : 0 }))
        ]);

        this.player = animation.create(this.el.nativeElement);
        this.player.play();
    }
}
