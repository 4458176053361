import { Component, Input, OnInit } from '@angular/core';

import { HiperLink } from "../../../core/models/hiperLink";
import { MiscService } from "../../../core/services/misc.service";

@Component({
    selector: 'app-hyperlink',
    templateUrl: './hyperlink.component.html',
    styleUrl: './hyperlink.component.scss'
})
export class HyperlinkComponent implements OnInit {
    @Input() hyperlinkInput: string | null = null; // Change to Input

    hyperlinkInfo: HiperLink | null = null;
    hyperlinkImage: string = 'assets/images/hyperlinkHub71.png';
    linkImage: string = 'assets/icons/link.svg';

    constructor(private misc: MiscService) { }

    async ngOnInit() {
        if (this.hyperlinkInput) {
            await this.getHyperLinkResponse(this.hyperlinkInput);
        }
    }

    openHyperlink() {
        if (this.hyperlinkInfo && this.hyperlinkInfo.url) {
            window.open(this.hyperlinkInfo.url, '_blank');
        } else {
            console.error("Hyperlink information or URL is missing.");
        }
    }

    async getHyperLinkResponse(hyperlink: string) {
        const response = await this.misc.fetchHiperLinkInfo(hyperlink);

        if (response.status !== 200 && response.body === null) {
            alert('Error while getting hyperlink');
            return;
        }

        this.hyperlinkInfo = response.body;

        if (!response.body.isImageUrl) {
            this.hyperlinkInfo.image = this.hyperlinkInfo.url + this.hyperlinkInfo.image;
        }

        return this.hyperlinkInfo;
    }

    get isValidHyperlink(): boolean {
        return this.hyperlinkInfo !== null
            && this.hyperlinkInfo.url !== ''
            && this.hyperlinkInfo.title !== null && this.hyperlinkInfo.title !== ''
            && this.hyperlinkInfo.description !== null && this.hyperlinkInfo.description !== '';
    }

    truncateDescription(description: string | undefined, limit: number): string {
        if(!description) return '';

        if (description.length > limit) {
            return description.substring(0, limit) + '...';
        }
        return description;
    }

    isValidImage(url: string | null | undefined): boolean {
        if(!url) return false;
        return /\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(url);
    }
}
